/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-10-14 14:37:04
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-10-15 19:55:38
 * @FilePath: \web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import '@/utils/pxtorem.js'
import requset from "./utils/request";
import loading from "@/utils/loding"

import "./style/index.scss";
import './assets/icon-font/iconfont.css'

import Video from 'video.js'
import "video.js/dist/video-js.css";

import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)


// element组件库
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$loading = loading
Vue.prototype.$videoJS = Video

new Vue({
  router,
  store,
  requset,
  render: h => h(App)
}).$mount("#app")
