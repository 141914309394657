import configInfo from "@/utils/config.js";
import axios from 'axios';
import store from "@/store";
import { Encrypt, Decrypt } from '@/utils/aes'
import { Message, MessageBox } from "element-ui";

// 基本配置
const instance = axios.create({
  // baseURL: 'https://api.xianyouhe.cn/', // 根据实际情况修改API地址
  baseURL: configInfo.baseApiDomain, // 根据实际情况修改API地址
  timeout: 5000 // 设置超时时间，单位为ms
});
 
// 请求拦截器
instance.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = token;
    config.headers['token'] = token;
  }
  return config;
}, error => {
  // console.log(error);
  return Promise.reject(error);
});
 
// 响应拦截器
instance.interceptors.response.use((response) => {
  // 可以在这里对响应数据进行一些统一的处理，比如判断状态码是否成功等
  let { data, status } = response;
  if (status === 200) {
      return data;
  } else {
      // 如果状态码不是200，可以抛出一个错误，在调用处进行处理
      throw new Error(`请求失败，状态码：${status}`);
  }
}, (error) => {
  // 在这里处理响应错误，比如网络错误、服务器返回错误等
  if (error.response) {
      // 服务器返回了错误响应，处理错误信息
      let { status, data } = error.response;
      if (status === 401) {
          // 比如401可能表示未授权，在这里可以做一些处理，比如跳转到登录页等
          // Vue.prototype.$router.push('/login');
      } else {
          // 其他错误码的处理
          console.error(`服务器返回错误，状态码：${status}，错误信息：${data}`);
      }
  } else if (error.request) {
      // 请求已经发出，但没有收到响应，可能是网络问题
      console.error('网络问题，请求未收到响应');
  } else {
      // 其他未知错误
      console.error('其他未知错误：', error);
  }

  return Promise.reject(error);
});

// 获取 Token 并检查是否过期
function getToken() {
  let tokenData = JSON.parse(localStorage.getItem('auth_token'));
  if (tokenData && new Date().getTime() < tokenData.expireTime) {
    return tokenData.token;
  } else {
    store.commit('user/setUserInfo', {})
    return null;
  }
}
 
export default instance;
 