//引入
import { Loading } from 'element-ui';
let globalMask = null
//打开遮罩层
const open = () => {
  globalMask = Loading.service({
    customClass: 'allLoding',
    lock: true,
    text: ``,               //提示文字
    // text: `${
    //   <div class="loader-42"></div>
    // }`,               //提示文字
    spinner: "el-icon-loading",        //图标
    background: 'rgba(0, 0, 0, 0.5)',  //遮罩层背景颜色
  })
}
//关闭遮罩层
const close = () => {
  if (globalMask) {
    globalMask.close()
  }
}
 
export default {
  open,
  close
}