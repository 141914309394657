let config = {
  // 接口域名
  domain: "apitest.xianyouhe.com",
  baseDomain: "https://apitest.xianyouhe.com",
  // baseApiDomain: "https://apitest.xianyouhe.com/api/",
  baseApiDomain: "https://api.xianyouhe.cn/",
  // domain: "apitest2.xianyouhe.com",
  // baseDomain: "https://apitest2.xianyouhe.com",
  // baseApiDomain: "https://apitest2.xianyouhe.com/api/",
  // 前台域名
  foregroundDomain: "https://csm.xianyouhe.com",
  // QQ登录appid
  QQAppID: "102066937",
  // 微信登录appid
  wechatAppID: "wxb43552711e4fe467",

  // // 接口域名
  // domain: "api.xianyouhe.com",
  // baseDomain: "https://api.xianyouhe.com",
  // baseApiDomain: "https://api.xianyouhe.com/api/",
  // // 前台域名
  // foregroundDomain: "https://m.xianyouhe.com",
  // // QQ登录appid
  // QQAppID: "102055018",
  // // 微信登录appid
  // wechatAppID: "wx505f045812b63321",

  // 采集系统接口域名
  gatherBaseApiDomain: "https://api.steammm.com/api/",

  // OSS配置
  region: "oss-cn-beijing",
  accessKeyId: "8g5NIK0GcUAmeUrOCaVYPtM9hXjz3A4sE6pS6VzjAxw=",
  accessKeySecret: "A8kaahicqB13nrzcX7SeY1xrUzVjDkhAiSnexS7/0q8=",
  bucket: "xianyouhecn",

  // OSS地址
  // imageUrl: "https://images.xianyouhe.com",
  imageUrl: "https://images.xianyouhe.cn",
  // https://images.xianyouhe.cn/uploads/20241122/173226303335100217000014.png
  // AES加密
  aesKey: "gbcdeg0922456789",
};

module.exports = config;
