import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store";
Vue.use(VueRouter)

const routes = [
	{
		path:'/',
		component: () => import("@/views/index/index"),
		redirect: "/home",
		children: [
			{
				path: 'home',
				name: 'Home',
				meta: {
					title: '首页',
				},
				component: () => import("@/views/home/index"),
			},
			{
				path: 'strikeRecovery',
				name: 'StrikeRecovery',
				meta: {
					title: '打击找回'
				},
				component: () => import("@/views/home/components/strikeRecovery.vue"),
			},
			{
				path: 'blackList',
				name: 'BlackList',
				meta: {
					title: '黑名单'
				},
				component: () => import("@/views/home/components/blackList"),
			},
			{
				path: 'newHelpList',
				name: 'NewHelpList',
				meta: {
					title: '公告帮助列表'
				},
				component: () => import("@/views/home/components/newHelpList.vue"),
			},
			{
				path: 'newHelpInfo',
				name: 'NewHelpInfo',
				meta: {
					title: '公告帮助详情'
				},
				component: () => import("@/views/home/components/newHelpInfo.vue"),
			},
			{
				path: 'buy',
				name: 'Buy',
				redirect: "/buy/product",
				meta: {
					title: '我要买'
				},
				component: () => import("@/views/buy/index.vue"),
				children: [
					{
						path: 'product',
						name: 'Product',
						meta: {
							title: '商品'
						},
						component: () => import("@/views/buy/components/product.vue"),
					},
					{
						path: 'productDetails',
						name: 'ProductDetails',
						meta: {
							title: '商品详情'
						},
						component: () => import("@/views/buy/components/productDetails.vue"),
					}
				]
			},
			{
				path: 'customerService',
				name: 'CustomerService',
				meta: {
					title: '客服'
				},
				component: () => import("@/views/customerService/index.vue"),
			},
			{
				path: 'help',
				name: 'Help',
				meta: {
					title: '帮助手册'
				},
				component: () => import("@/views/help/index.vue"),
			},
			{
				path: 'user',
				name: 'User',
				meta: {
					title: '个人中心'
				},
				component: () => import("@/views/user/index.vue"),
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',  // 启用 History 模式
	routes,
	scrollBehavior(to, from, savedPosition) {
		// 每次路由跳转时，滚动到页面顶部
		return { x: 0, y: 0 };
	  }
})

router.beforeEach((to, from, next) => {
	if (to.meta && to.meta.title) {
		document.title = '游戏账号交易平台 - 轻松买卖账号 - 快速找到心仪的游戏账号 -' + to.meta.title;  // 设置动态标题
	}
    // 从本地存储中获取token相关数据
    let tokenData = JSON.parse(localStorage.getItem('auth_token'));

	let isTokenValid = (tokenData) => {
        return tokenData && tokenData.token && new Date().getTime() < tokenData.expireTime;
    };

    // 判断要跳转的路径是否为个人中心页面
    if (to.path === '/user') {
        if (isTokenValid(tokenData)) {
            // 如果有token且未超过有效期，允许正常跳转
            next();
        } else {
            // 如果没有token或者已超过有效期，跳转到首页
			store.commit('user/setUserInfo', {})
			store.commit('user/setMenu', 1)
            next('/');
            // 刷新页面，确保用户看到的是最新状态
            location.reload();
        }
    } else {
        // 对于其他非个人中心页面的路由跳转，直接允许跳转
		if (isTokenValid(tokenData)) {
            next();
        } else {
			store.commit('user/setUserInfo', {})
        }
        next();
    }
});

export default router
