import request from '@/utils/request'// 这里是引入咱们的axios用axios来进行异步接口请求
import { Encrypt, Decrypt } from '@/utils/aes'

// 个人中心 - 获取会员详细信息
export function getUserinfo(data){
    return request({
        url:'/api/user/getUserinfo',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 个人中心 - 我的收藏列表
export function getCollectList(data){
    return request({
        url:'/api/news/getCollectList',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 收藏/取消收藏 新闻资讯/商品
export function collectNews(data){
    return request({
        url:'/api/news/collectNews',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 修改用户昵称头像
export function editUserInfo(data){
    return request({
        url:'/api/user/editUserInfo',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}