import { getUserinfo } from '@/api/user'
import { getWebsite } from '@/api/home'
import { Message, MessageBox } from "element-ui";
import { Encrypt, Decrypt } from '@/utils/aes'
const state = {
    userInfo: {}, // 用户信息
    menuActive: 1, // 当前激活的左侧菜单
    headerInput: '', // 头部搜索框
    websiteData: {}, // 网站基本信息
};

const mutations = {
    // 修改用户信息
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo;
    },

    // 修改当前激活的左侧菜单
    setMenu(state, type) {
        state.menuActive = type;
    },

    // 修改头部搜索框
    setHeaderInput(state, val) {
        // console.log('jinlaile', 123123132)
        state.headerInput = val;
    },

    // 修改网站基本信息
    setWebsiteData(state, val) {
        state.websiteData = val
    }
};

const getters = {
    getUserInfo: (state) => state.userInfo,
    getHeaderInput: (state) => state.headerInput,
};

const actions = {
    // 获取用户信息
    fetchUserInfo({ commit }) {
        getUserinfo().then(res => {
            if (res.code != 1) return this.$message.error(res.msg)
            // console.log(JSON.parse(Decrypt(res.data)).userinfo, 'JSON.parse(Decrypt(res.data)).userinfo')
            commit('setUserInfo', JSON.parse(Decrypt(res.data)).userinfo);
        })
    },

    // 获取网站基本信息
    postWebsite({ commit }) {
        getWebsite().then(res => {
            if (res.code != 1) return
            let data = JSON.parse(Decrypt(res.data))
            commit('setWebsiteData', data);
            // this.websiteData = data

            // console.log(data, 'data')
        })
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
