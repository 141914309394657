<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-10-14 14:37:04
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-10-15 14:27:48
 * @FilePath: \web\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
export default {
  name: 'App',

  data() {
    return {

    }
  },

  computed: {
    ...mapState('user', {
        websiteData: 'websiteData'
    })
  },

  mounted() {
    this.$nextTick(() => {
      this.changeFavicon();
    })
  },

  methods: {
    changeFavicon() {
      const favicon = document.getElementById('favicon');

      if (favicon) {
        favicon.href = this.websiteData.favicon_image;
      }
    },
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
		height: 100%;
		overflow: hidden;
}
</style>
