//接口
//引入axios 
import request from '@/utils/request'// 这里是引入咱们的axios用axios来进行异步接口请求
import { Encrypt, Decrypt } from '@/utils/aes'

// 获取网站基本信息
export function getWebsite(data){
    return request({
        url:'/api/common/getWebsite',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 首页获取黑名单列表
export function getBlackList(data){
    return request({
        url:'/api/news/getBlackList',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 获取客服列表
export function getCustomerInfo(data){
    return request({
        url:'/api/news/getCustomerInfo',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}
// 首页- 轮播图
export function getCarouselImg(data){
    return request({
        url:'/api/index/getAd',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 首页 - 成交记录
export function getOrderList(data){
    return request({
        url:'/api/index/getOrderList',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 首页 - 获取通知
export function getNotice(data){
    return request({
        url:'/api/news/getNotice',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 首页 - 获取底部友情链接
export function getWebsiteLink(data){
    return request({
        url:'/api/common/getWebsiteLink',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 登录注册 - 获取短信验证码
export function sendSmsCode(data){
    return request({
        url:'/api/sms/sendSmsCode',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 登录注册 - 验证码登录
export function phoneLogin(data){
    return request({
        url:'/api/user/phoneLogin',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 首页 最新公告/帮助中心  列表
export function getNewsList(data){
    return request({
        url:'/api/news/getNewsList',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 首页 - 获取交易数据
export function getTradeInfo(data){
    return request({
        url:'/api/index/getTradeInfo',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}

// 个人中心 获取 服务协议/隐私协议 详情 category_id  6-公告、7-帮助、8-指南、9-服务协议、10-隐私协议、11-卖号网用户协议
export function getNewsDetail(data){
    return request({
        url:'/api/news/getNewsDetail',
        method:'post',
        data,  // 一般post请求，我们习惯使用 data属性来传参
    })
}