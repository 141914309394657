import CryptoJS from 'crypto-js';

/**
 * 加密函数
 * @param {string} word - 要加密的字符串
 * @param {string} keyStr - 加密密钥
 * @param {string} iv - 初始化向量
 */
export function Encrypt(word, keyStr = 'gbcdeg0922456789', iv) {
    // 将密钥和初始化向量转换为 CryptoJS 可用的格式
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    // const ivParsed = CryptoJS.enc.Utf8.parse(iv);
    // 将输入的字符串转换为 UTF-8 格式
    const srcs = CryptoJS.enc.Utf8.parse(word);
    // 进行 AES 加密，设置加密模式为 CBC，补码方式为 Pkcs7
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
        // iv: ivParsed,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString(); // 直接返回加密结果的字符串表示
}

/**
 * 解密函数
 * @param {string} word - 要解密的 Base64 字符串
 * @param {string} keyStr - 解密密钥
 * @param {string} iv - 初始化向量
 * @returns {string} 解密后的字符串
 */
export function Decrypt(word, keyStr = 'gbcdeg0922456789', iv) {
    // 将密钥和初始化向量转换为 CryptoJS 可用的格式
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    // const ivParsed = CryptoJS.enc.Utf8.parse(iv);
    // 进行解密操作
    const decrypt = CryptoJS.AES.decrypt(word, key, {
        // iv: ivParsed,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    // 将解密后的结果转换为 UTF-8 字符串并返回
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr; // 返回解密后的字符串
}
