import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/store/modules/user";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user
  },

  plugins: [
    createPersistedState({
      storage: window.localStorage
    })
  ]
})

// const store = new Vuex.Store({
//   modules: {
//     user
//   }
// })
export default store
